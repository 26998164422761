<template>
    <div class="flex-align boxs">
        <div>
            <el-select v-model="form.province" placeholder="省份" style="width: 130px; margin-right: 12px" @change="op">
                <el-option v-for="item in province" :key="item.code" :label="item.name" :value="item.code" />
            </el-select>
            <el-select v-model="form.city" placeholder="城市" style="width: 130px; margin-right: 12px">
                <el-option v-for="item in city" :key="item.code" :label="item.name" :value="item.code" />
            </el-select>
            <div class="box">
                <el-input v-model="form.address" :maxlength="100" show-word-limit />
            </div>
        </div>
        <el-button type="text" icon="el-icon-remove" style="margin-left: 10px;color:red" @click="toDelete">删除</el-button>
    </div>
</template>

<script>
import { debounce } from "@/utils/methods.js";

export default {
    props: {
        province: {
            type: [Array],
            default() {
                return [];
            },
        },
        value: {
            type: [Array],
            default() {
                return [];
            },
        },
        index: {
            type: [Number, String],
            default() {
                return "";
            },
        },
        detail: {
            type: [Object],
            default() {
                return {};
            },
        },
    },
    watch: {
        detail: {
            handler(val) {
                this.form = val;
            },
            immediate: true,
        },
        form: {
            handler(val) {
                var list = [...this.value];
                this.$set(list, this.index, val);
                this.$emit("input", list);
            },
            deep: true,
        },
        "form.province": {
            handler(val) {
                if (this.first && val) {
                    this.getc(1);
                    this.first = false;
                }
            },
            immediate: true,
        },
    },
    data() {
        return {
            form: { province: "", city: "", address: "" },
            city: [],
            first: true,
        };
    },
    methods: {
        op() {
            this.getc();
        },
        getc: debounce(async function(val) {
            if (!val) this.form.city = "";
            const { data: res } = await this.$store.dispatch("getCity", this.form.province);
            this.city = res;
        }, 200),
        toDelete() {
            var list = [...this.value];
            this.$delete(list, this.index);
            this.$emit("input", list);
        },
    },
};
</script>

<style lang="less" scoped>
.boxs {
    margin-top: 10px;
}
</style>
